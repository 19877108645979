/* Add this to your existing CSS code */

/* Add fade-in effect to header content */
.server-info {
  opacity: 0;
  animation: fade-in-info 1.8s ease forwards;
}

@keyframes fade-in-info {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-btn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Add fade-in effect to buttons */
.discord-link,
.donate-link {
  opacity: 0;
  animation: fade-in-btn 1.8s ease forwards;
}
