html,
body,
#root,
.App {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
}

.App > .Routes {
  flex-grow: 1;
}

body {
  background: linear-gradient(
    to bottom,
    rgba(20, 20, 20, 1) 0%,
    rgba(50, 50, 50, 1) 100%
  );
  margin: 0;
  font-family: "Roboto", sans-serif;
  height: auto;
  background-attachment: fixed; /* Add this line */
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500&display=swap");

.homepage {
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
}

.home-icon {
  position: relative;
  bottom: 2px;
}

.navbar {
  background-color: #280f46;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  z-index: 100;
  transition: all 0.3s ease;
}

.navbar-list {
  margin: 0.6rem auto 0 auto;
  list-style-type: none;
  display: flex;

  padding: 0.5rem 0;
}

.navbar-list li a {
  color: white;
  text-decoration: none;
  padding: 0 10px;
  transition: color 0.3s ease;
}

.navbar-list li a:hover {
  color: #9b72c9;
}

.header-image {
  position: relative;
  width: 100vw;
  height: calc(100vh - 60px);
  overflow: hidden;
}

.navbar-link {
  color: white;
  text-decoration: none;
  padding: 0 10px;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: #9b72c9;
  cursor: pointer; /* Add pointer cursor */
}

/* Optional: add styles for the Introduction and Tutorial sections */
.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.introduction,
.tutorial {
  padding: 50px;
  background-color: rgba(75, 40, 114, 0.7);
  backdrop-filter: blur(10px);
  border-radius: 15px;
  margin: 30px auto;
  max-width: 800px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.introduction:hover,
.tutorial:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 768px) {
}

.buffer {
  padding-top: 60px;
}

/* Add styles for the Introduction and Tutorial headings */
.introduction h2,
.tutorial h2 {
  font-size: 36px;
  margin-bottom: 10px;
}

/* Add styles for the Introduction and Tutorial paragraphs */
.introduction p,
.tutorial p {
  font-size: 18px;
  line-height: 1.6;
}

.tutorial ul {
  list-style-type: square;
  text-align: left;
  display: inline-block;
  margin: 0;
  padding: 0 40px;
}

.tutorial li {
  font-size: 16px;
  margin-bottom: 8px;
}

.treeImage {
  width: 100vw;
  height: calc(100vh - 60px);
  object-fit: cover;
}

.server-info {
  position: absolute;
  top: 38%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.header-text {
  font-size: 100px;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
  margin: 0;
}

.header-ip {
  font-size: 30px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
  font-weight: bold;
  margin: 10px 0;
}

.header-type {
  font-size: 24px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 1);
  font-weight: bold;
}

.discord-link {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.donate-link {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.btn {
  display: inline-block;
  padding: 15px 0px;
  width: 10rem;
  margin: 10px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.btn-donate {
  background-color: #9b72c9;
  color: white;
  padding: 10px 0 10px;
}

.btn-discord {
  background-color: #3498db;
  color: white;
}

.btn:hover {
  opacity: 0.9;
  transform: translateY(-2px) scale(1.05);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 527px) {
  .navbar-list {
    font-size: 0.7rem;
  }
  .home-icon {
    height: 18px;
  }
}
@media screen and (min-width: 528px) {
  .navbar-list {
    font-size: 0.9rem;
    gap: 0.8rem;
  }
  .home-icon {
    height: 23px;
  }
}
@media screen and (min-width: 900px) {
  .navbar-list {
    font-size: 1rem;
    gap: 2rem;
  }
  .home-icon {
    height: 25px;
  }
}
@media screen and (min-width: 1200px) {
  .navbar-list {
    font-size: 1.3rem;
    gap: 4rem;
  }
  .home-icon {
    height: 31px;
  }
}

/* START OF DONATIONS PAGE */

.donation-page {
  text-align: center;
  color: white;
  font-family: "Poppins", sans-serif;
  padding: 40px 0;
  background: linear-gradient(
    to bottom,
    rgba(20, 20, 20, 1) 0%,
    rgba(50, 50, 50, 1) 100%
  );
  background-size: cover;
  background-attachment: fixed;
  height: auto;
}

.ranks-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
}

.rank-card {
  backdrop-filter: blur(10px);
  border-radius: 15px;
  padding: 20px;
  margin: 20px;
  width: 300px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: 0.3s;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.rank-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.rank-card h2 {
  margin: 0;
  margin-bottom: 10px;
}

.rank-card h3 {
  margin-top: 0;
  margin-bottom: 20px;
}

.perks-list {
  list-style-type: disc;
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  text-align: left;
}

.rank-card li {
  margin-bottom: 5px;
}

.kit-list {
  list-style-type: disc;
  padding: 0;
  margin: 0;
  margin-left: 20px;
  margin-bottom: 20px;
  text-align: left;
}

.btn-view-kit {
  background-color: #9b72c9;
  color: white;
  margin-left: 0px;
  font-size: 12px;
  padding: 3px 5px;
  border-radius: 5px;
  cursor: pointer;
  width: 7rem;
  border: none;
  transition: 0.3s;
}

.btn-view-kit:hover {
  background-color: #ad8de4;
}

.btn-back {
  background-color: white;
  color: black;
}

.donate-button-wrapper {
  margin-top: auto;
}

.perks-wrapper {
  padding: 0 10px;
}

/* END OF DONATIONS PAGE */

/* RESPONSIVE STYLES */

@media screen and (max-width: 1024px) {
  .ranks-container {
    flex-direction: column;
  }

  .rank-card {
    width: 80%;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
  .header-text {
    font-size: 60px;
  }
}

.disclaimer {
  font-size: 14px;
  margin-top: 20px;
  text-align: justify;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 800px;
}

@media screen and (max-width: 500px) {
  .discord-link,
  .donate-link {
    display: none;
  }
}

.donation-intro {
  margin: 0 auto;
  padding: 0 1rem;
  max-width: 900px;
}

.terms-link {
  color: rgb(123, 59, 175);
  font-weight: bold;
  text-decoration: none;
  transition: all 0.2s ease;
}

.terms-link:hover {
  color: rgb(96, 23, 156);
  text-decoration: underline;
}

/* END OF RESPONSIVE STYLES */

.footer {
  width: 100%;
  background-color: #222;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  font-size: 14px;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

.footer a:hover {
  color: #ccc;
}

.footer-copyright {
  margin-top: 10px;
}

.footer-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  max-width: 800px;
  margin: 0 auto;
}
.terms-of-service {
  font-family: Arial, sans-serif;
  padding: 20px;
}

.content {
  max-width: 800px;
  margin: 0 auto;
}

.terms-of-service h1 {
  margin-bottom: 10px;
  font-size: 34px;
  color: white;
  text-align: center;
}

.terms-intro {
  text-align: center;
  margin-bottom: 70px;
}

.conditions {
  margin-top: 40px;
}

.terms-of-service h2 {
  margin-bottom: 10px;
  font-size: 24px;
  color: white;
}

.terms-of-service p,
ul {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: rgb(201, 201, 201);
}

.terms-of-service ul {
  padding-left: 30px;
}

/* CUSTOM STYLES */

/* Add your custom styles below this comment */

/* END OF CUSTOM STYLES */
